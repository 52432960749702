@font-face {
  font-family: 'Ethnocentric';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/Ethnocentric-V2.woff2') format('woff2');
}

@font-face {
  font-family: 'Ash';
  src: url('./assets/fonts/Ash.woff2') format('woff2');
}

.truncate {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.marquee {
  padding-top: 20px;
  height: 70px;
  width: 100%;

  overflow: hidden;
  position: relative;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;

  position: absolute;
  overflow: hidden;

  animation: marquee 5s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@-webkit-keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

#rssBlock {
  left: 0px;
  height: 70px;
  background: #c03234;
  transform: 'scale(1.1, 1.5)';
  /* position: absolute; */
  /* padding-bottom: 5px; */
  width: 100%;
  overflow: hidden;
}

/*remove p*/
.cnnContents {
  width: 100%;
  margin: 0 auto;
  /* white-space: nowrap; */
  /* text-transform: uppercase; */
  display: inline-block;
}

.marqueeStyle2 {
  display: inline-block;
  -webkit-animation: scrolling-left2 15s linear infinite;
  animation: scrolling-left2 15s linear infinite;
  /* animation-delay: 10s; */
}

@keyframes scrolling-left2 {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }

  50% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }

  51% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
}

@-webkit-keyframes scrolling-left2 {
  0% {
    -webkit-transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-200%);
  }
}